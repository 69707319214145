$netstorage-font-path: '/_fonts/smashbox/'; // Netstorage path.

$BebasNeue_bold-font: 'Bebas Neue Bold';

@font-face {
  font-family: '#{$BebasNeue_bold-font}';
  font-style: normal;
  src: url('#{$netstorage-font-path}cyrillic/BebasNeue/ofont_ru_Bebas_Neue_Bold.woff2')
    format('woff2');
  font-display: swap;
}

$BebasNeue_cyrillic-font: 'Bebas Neue Cyrillic';

@font-face {
  font-family: '#{$BebasNeue_cyrillic-font}';
  font-style: normal;
  src: url('#{$netstorage-font-path}cyrillic/BebasNeue/ofont_ru_Bebas_Neue_Cyrillic.woff2')
    format('woff2');
  font-display: swap;
}

$font--heading-main: $BebasNeue_bold-font;
$font--subheading-alt: $BebasNeue_bold-font;
$font--subheading: $BebasNeue_cyrillic-font;
$font--heading-display: $BebasNeue_cyrillic-font;
